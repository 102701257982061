import type { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { useRef, useState } from 'react'

import { changePassword } from '../../../../store/actions'
import { toggleLabelInvalidClass } from './AccountMenu'
import { useTranslate } from '../../../../utils/translate'
import PasswordRevealInput from '../PasswordRevealInput'
import useAutoFocus from '../../../../utils/hooks/useAutoFocus'

type Props = { onClose: () => void }

export default function PasswordForm({ onClose }: Readonly<Props>): ReactElement {
  const dispatch: ApiActionDispatch = useDispatch<GlobalDispatch>()

  const [error, setError] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const t = useTranslate('shop', 'views.storefrontView.customerAccountSection.profileTab.passwordSection.editModal')

  async function handleSubmit(event) {
    event.preventDefault()
    setSubmitting(true)

    try {
      setError(null)
      const currentPassword = event.target.elements.currentPassword.value
      const newPassword = event.target.elements.newPassword.value

      await dispatch(changePassword({ currentPassword, newPassword }, { showErrorNotification: false }))
      onClose()
    } catch (error) {
      // scroll up to the error message
      form.current?.scrollIntoView(true)

      if (/current password.*not match/.test(error.message)) {
        setError(t('errors.currentPasswordDoesNotMatch'))
        toggleLabelInvalidClass(true)(form.current?.currentPassword)
      } else {
        setError(t('errors.genericServerError'))
      }
    } finally {
      setSubmitting(false)
    }
  }

  const form = useRef<HTMLFormElement>(null)
  useAutoFocus(form)

  return (
    <form
      ref={form}
      onSubmit={handleSubmit}
      onInvalid={toggleLabelInvalidClass(true)}
      onInput={toggleLabelInvalidClass(false)}
      autoComplete="on"
      className="lightbox-modal-form"
    >
      <div className="inner-content">
        <h2 className="title">{t('title')}</h2>
        {error && <p className="error-message">{error}</p>}
        <label>
          {t('currentPasswordField.label')}*
          <PasswordRevealInput autoFocus name="currentPassword" autoComplete="password" required />
        </label>
        <label>
          {t('newPasswordField.label')}*
          <PasswordRevealInput name="newPassword" autoComplete="new-password" minLength={8} required />
        </label>
      </div>
      <div className="button-wrapper">
        <button className="button button-primary button-pending-state pending" type="submit" disabled={submitting}>
          {t('saveButton.label')}
        </button>
        <button className="button button-outline" type="button" onClick={onClose}>
          {t('cancelButton.label')}
        </button>
      </div>
    </form>
  )
}
