import type { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRef, useState } from 'react'

import { assignCustomerToCart } from '../../../../store/actions'
import { getPlain } from '../../../../store/utils'
import { toggleLabelInvalidClass } from './AccountMenu'
import { updateCustomerBillingAddress } from '../../../../store/actions/customer'
import { useTranslate } from '../../../../utils/translate'
import useAutoFocus from '../../../../utils/hooks/useAutoFocus'

type Props = {
  onClose: () => void
  billingAddress: Core.Customer['billingAddress']
}

export default function MasterDataForm({ onClose, billingAddress }: Readonly<Props>): ReactElement {
  const dispatch: ApiActionDispatch = useDispatch<GlobalDispatch>()

  const cart = getPlain(useSelector<State, Core.Cart | null>((state) => state.get('cart', null)))

  const [submitting, setSubmitting] = useState(false)

  const t = useTranslate('shop', 'views.storefrontView.customerAccountSection.profileTab.masterDataSection.editModal')

  async function handleSubmit(event) {
    event.preventDefault()
    setSubmitting(true)

    try {
      const firstName = event.target.elements.firstName.value
      const lastName = event.target.elements.lastName.value
      const phone = event.target.elements.phone.value || null

      await dispatch(updateCustomerBillingAddress({ ...billingAddress, firstName, lastName, phone }))
      // repeat assignment in order to update address data in cart
      if (cart?.productLineItems?.length) await dispatch(assignCustomerToCart())
    } catch {
      // global API error handler takes care
    } finally {
      setSubmitting(false)
    }

    onClose()
  }

  const form = useRef<HTMLFormElement>(null)
  useAutoFocus(form)

  return (
    <form
      ref={form}
      onSubmit={handleSubmit}
      onInvalid={toggleLabelInvalidClass(true)}
      onInput={toggleLabelInvalidClass(false)}
      className="lightbox-modal-form"
    >
      <div className="inner-content">
        <h2 className="title">{t('title')}</h2>
        <label>
          {t('firstNameInputField.label')}*
          <input defaultValue={billingAddress.firstName} name="firstName" type="text" required />
        </label>
        <label>
          {t('lastNameInputField.label')}*
          <input defaultValue={billingAddress.lastName} name="lastName" type="text" required />
        </label>
        <label>
          {t('phoneInputField.label')}
          <input defaultValue={billingAddress.phone || ''} name="phone" type="tel" />
        </label>
      </div>
      <div className="button-wrapper">
        <button className="button button-primary button-pending-state pending" type="submit" disabled={submitting}>
          {t('saveButton.label')}
        </button>
        <button className="button button-outline" type="button" onClick={onClose}>
          {t('cancelButton.label')}
        </button>
      </div>
    </form>
  )
}
