import type { ReactElement } from 'react'
import { browserHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import cc from 'classcat'

import { LegalPageLink } from './AccountMenu'
import {
  generateCustomerAccountUrl,
  generateCustomerOrdersUrl,
  generateShopUrl,
  withQueryAndScope,
} from '../../../../urlGenerators'
import { getPlain } from '../../../../store/utils'
import { logoutCustomer } from '../../../../store/actions/customer'
import { useTranslate } from '../../../../utils/translate'
import AccountDeleteModal from './AccountDeleteModal'
import CustomerAddress from './CustomerAddress'
import CustomerOrders from './CustomerOrders'
import CustomerProfile from './CustomerProfile'
import Link from '../../../templateComponents/Link'

const subComponents = {
  orders: CustomerOrders,
  profile: CustomerProfile,
  address: CustomerAddress,
  settings: Settings,
}
const leftMenu = ['orders', 'profile']
const profileTabs = Object.keys(subComponents).filter((value) => value !== 'orders')

const customerAccountUrl = generateCustomerAccountUrl()
const customerOrdersUrl = generateCustomerOrdersUrl()

export default function Account({ section = 'profile' }: Readonly<{ section?: string }>): ReactElement {
  const dispatch: ApiActionDispatch = useDispatch<GlobalDispatch>()
  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))

  const { loggedIn = false, requestedNewEmail } =
    getPlain(useSelector<State, Record<string, any>>((state) => state.get('customer'))) || {}

  const { firstName, lastName, email } = getPlain(
    useSelector<State, Core.Customer['billingAddress']>((state) => state.getIn(['customer', 'billingAddress'], {})),
  )
  const name = [firstName, lastName].filter(Boolean).join(' ')

  const SectionComponent: () => ReactElement = subComponents[section] || subComponents.profile

  const t = useTranslate('shop', 'views.storefrontView.customerAccountSection')

  useEffect(() => {
    if (!subComponents[section]) browserHistory.replace(customerAccountUrl)
  }, [section])

  async function handleLogout() {
    try {
      await dispatch(logoutCustomer())
      browserHistory.push(withQueryAndScope(generateShopUrl(), location))
    } catch {
      // global API call error handler takes care
    }
  }

  function isMenuItemOpened(menuName: string): boolean {
    if (section === 'orders') {
      return section === menuName
    }

    return menuName === 'profile'
  }

  return (
    <section className={cc(['section account', { 'account-orders': section === 'orders', 'logging-out': !loggedIn }])}>
      <div className="wrapper">
        <div className="mobile-orders-back-link-wrapper">
          <Link className="mobile-orders-back-link" to={customerAccountUrl}>
            {t('backButton.label')}
          </Link>
        </div>
        <div className="shadow"></div>
        <div className="mobile-orders-heading-wrapper">
          <div className="shadow-cover"></div>
          <h1 className="mobile-orders-heading">{t('ordersTab.menu.title')}</h1>
        </div>
        <h1 className="heading">{t('welcomeMessage', { name })}</h1>
        <button className="button-link logout-link" onClick={handleLogout}>
          {t('logoutButton.label')}
        </button>
        {requestedNewEmail && requestedNewEmail !== email && (
          <div className="warning-message">{t('notifications.requestedNewEmail', { newEmail: requestedNewEmail })}</div>
        )}
        <Link className="mobile-orders-link" to={customerOrdersUrl}>
          Orders
        </Link>
        <nav className="account-menu">
          <ul>
            {leftMenu.map((name) => {
              const subPath = name !== 'profile' && name
              const text = t(`${name}Tab.menu.title`)

              if (isMenuItemOpened(name)) {
                return (
                  <li key={name} className="active-item">
                    <span>{text}</span>
                  </li>
                )
              } else {
                return (
                  <li key={name}>
                    <Link to={[customerAccountUrl, subPath].filter(Boolean).join('/')}>{text}</Link>
                  </li>
                )
              }
            })}
          </ul>
        </nav>
        {section !== 'orders' && <ProfileSubMenu t={t} section={section} />}
        <SectionComponent />
      </div>
    </section>
  )
}

function ProfileSubMenu({ t, section }: Readonly<{ section?: string } & TranslateProps>) {
  return (
    <nav className="subpage-nav">
      <ul>
        {profileTabs.map((name) => {
          const subPath = name !== 'profile' && name
          const text = t(`${name}Tab.title`)

          if (section === name) {
            return (
              <li key={name} className="active-item">
                <span>{text}</span>
              </li>
            )
          } else {
            return (
              <li key={name}>
                <Link
                  to={{
                    pathname: [customerAccountUrl, subPath].filter(Boolean).join('/'),
                    state: { scrollToTop: false },
                  }}
                >
                  {text}
                </Link>
              </li>
            )
          }
        })}
        <div className="active-item-indicator"></div>
      </ul>
    </nav>
  )
}

function Settings() {
  const [showModal, setShowModal] = useState(false)

  const t = useTranslate('shop', 'views.storefrontView.customerAccountSection.settingsTab')

  return (
    <section className="subpage subpage-settings">
      <div className="subpage-item">
        <h2 className="subpage-item-headline-title">{t('dataPrivacySection.title')}</h2>
        <LegalPageLink slug="privacy" />
      </div>
      <div className="subpage-item">
        <h2 className="subpage-item-headline-title">{t('deleteProfileSection.title')}</h2>
        <p>{t('deleteProfileSection.explanation')}</p>
        <button className="button-link" onClick={() => setShowModal(true)}>
          {t('deleteProfileSection.deleteProfileButton.label')}
        </button>
      </div>
      <AccountDeleteModal show={showModal} onClose={() => setShowModal(false)} />
    </section>
  )
}
