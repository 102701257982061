import { useSelector } from 'react-redux'

import { useTranslate } from '../../../../utils/translate'
import Link from '../../../templateComponents/Link'

export default function OrderEmptyNote() {
  const shopHref = useSelector<State, string>((state) => state.getIn(['shop', 'href']))
  const t = useTranslate('shop', 'views.storefrontView.customerAccountSection.orderOverview.emptyState')

  return (
    <section className="subpage orders-empty">
      <p className="orders-empty-text">{t('explanation')}</p>
      <Link className="button button-primary" to={shopHref}>
        {t('continueShoppingLink')}
      </Link>
    </section>
  )
}
