import type { Location } from 'history'
import type { ReactElement } from 'react'
import { browserHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import { getPlain } from '../../../../store/utils'
import { listOrders } from '../../../../store/actions/customer'
import { useTranslate } from '../../../../utils/translate'
import Link from '../../../templateComponents/Link'
import OrderEmptyNote from './OrderEmptyNote'
import useDateTimeFormatter from '../../../../utils/hooks/useDateTimeFormatter'

const pageSize = 10

export default function CustomerOrders(): ReactElement {
  const location = getPlain<Location>(useSelector<State, ImmutableMap>((state) => state.get('location')))

  const page = typeof location.query.page === 'string' ? Math.max(1, parseInt(location.query.page)) || 1 : 1

  const dispatch = useDispatch<GlobalDispatch>() as ApiActionDispatch

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const [orders, setOrders] = useState<Core.CustomerOrder[]>([])
  const [totalNumberOfOrders, setTotalNumberOfOrders] = useState(0)

  const t = useTranslate('shop', 'views.storefrontView.customerAccountSection.orderOverview')

  const node = useRef<HTMLElement>(null)

  function loadOrders({ page, size }) {
    return dispatch(listOrders({ page, size }))
      .then((response) => {
        setOrders(orders.concat(response.orders))
        if (!totalNumberOfOrders) setTotalNumberOfOrders(response.totalNumberOfOrders)
        if (page !== 1) browserHistory.replace({ ...location, query: { ...location.query, page } })
      })
      .catch(() => null)
      .finally(() => {
        setIsLoading(false)
        setIsLoadingMore(false)

        // prevent horizontal scroll bar caused by mobile back link
        const scrollbarWidth = window.innerWidth - document.body.clientWidth
        node.current?.parentElement?.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`)
      })
  }

  useEffect(() => {
    loadOrders({ page: 1, size: pageSize * page })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function loadMore() {
    setIsLoadingMore(true)
    await loadOrders({ page: page + 1, size: pageSize })
    setIsLoadingMore(false)
  }

  const dateTimeOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }

  const monthOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
  }

  const formatDateTime = useDateTimeFormatter()

  const translationKeysForDocuments = {
    'invoice-pdf': 'invoiceDownloadPdfLink',
    'invoice-correction-pdf': 'invoiceCorrectionDownloadPdfLink',
    'invoice-cancelation-pdf': 'invoiceCancelationDownloadPdfLink',
  }

  let lastGroupingDateTitle = ''

  function shouldRenderOwnGroupingDateTitleForThisOrder(order: Core.CustomerOrder): boolean {
    const currentGroupingTitle = formatDateTime(order.orderDate.toString(), monthOptions)
    const result = currentGroupingTitle !== lastGroupingDateTitle
    lastGroupingDateTitle = currentGroupingTitle

    return result
  }

  if (!isLoading && orders.length === 0) return <OrderEmptyNote />

  return (
    <section className="subpage orders" ref={node}>
      {isLoading ? (
        <span className="spinner"></span>
      ) : (
        <>
          <ol className="orders-list">
            {orders.map((order, index) => (
              <li className="orders-list-item" key={order.orderNumber}>
                {index === 0 ? (
                  <h2 className="orders-date-title">{t('latest.label')} </h2>
                ) : (
                  shouldRenderOwnGroupingDateTitleForThisOrder(order) && (
                    <h2 className="orders-date-title">{formatDateTime(order.orderDate.toString(), monthOptions)}</h2>
                  )
                )}
                <div className="orders-order">
                  <div className="orders-order-row">
                    <dl className={`orders-order-status icomoon-${order.shippingStatus.toLowerCase()}`}>
                      <dt className="orders-order-status-key visually-hidden">
                        {t('shippingStatus.accessibilityLabel')}
                      </dt>
                      <dd className="orders-order-status-value">{t('shippingStatus.' + order.shippingStatus)}</dd>
                    </dl>
                    <time>{formatDateTime(order.orderDate.toString(), dateTimeOptions)}</time>
                  </div>
                  <div className="orders-order-row">
                    <dl className="orders-order-data">
                      <dt className="orders-order-data-key">{t('grandTotal')}</dt>
                      <dd className="orders-order-data-value">{order.grandTotal.formatted}</dd>
                    </dl>

                    {order.documents?.length > 0 && (
                      <ul className="orders-order-invoice-link-list">
                        {order.documents.map((orderDocument) => (
                          <li key={orderDocument.invoiceNumber}>
                            <Link className="orders-order-invoice-link" href={orderDocument.href} target="_blank">
                              {t('documentSection.' + translationKeysForDocuments[orderDocument.label] + '.label', {
                                number: orderDocument.invoiceNumber,
                              })}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="orders-order-row">
                    <dl className="orders-order-data">
                      <dt className="orders-order-data-key">{t('orderNumber')}</dt>
                      <dd className="orders-order-data-value">{order.orderNumber}</dd>
                    </dl>

                    {order.trackingLinks?.length > 0 && (
                      <ul className="orders-order-tracking-link-list">
                        {order.trackingLinks.map((trackingLink) => (
                          <li key={trackingLink.href}>
                            <Link className="orders-order-tracking-link" href={trackingLink.href} target="_blank">
                              {t('trackingLink.label')}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ol>
          {isLoadingMore ? (
            <span className="spinner" />
          ) : (
            orders.length < totalNumberOfOrders && (
              <button className="button button-primary orders-load-more" type="button" onClick={() => loadMore()}>
                {t('loadMoreButton.label')}
              </button>
            )
          )}
        </>
      )}
    </section>
  )
}
