import type { RefObject } from 'react'
import { useEffect } from 'react'

// iOS Safari 🤷: https://caniuse.com/autofocus
export default function useAutoFocus(form: RefObject<HTMLFormElement | null>): void {
  useEffect(() => {
    const firstInputInForm = Array.from(form?.current?.elements || []).find(
      (el) => el.tagName !== 'FIELDSET',
    ) as HTMLInputElement
    if (firstInputInForm?.focus) {
      firstInputInForm.focus()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
